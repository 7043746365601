import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useHits } from 'react-instantsearch';

import { stepByStepPageSlugs } from '@boss/constants/b2b-b2c';
import { ISearchStepByStepPlan } from '@boss/types/b2b-b2c';
import { appendValueToSlugsObject } from '@boss/utils';

import { useSearchMenuResults } from '../../hooks';
import VirtualSearchBox from '../Algolia/VirtualSearchBox';

interface Props {
  query: string;
  indexName: string;
}

const StepByStepResults = ({ query, indexName }: Props) => {
  const { results } = useHits<ISearchStepByStepPlan>();
  const { t } = useTranslation();
  const { locale } = useRouter();

  const { updateStepByStepResults } = useSearchMenuResults();

  useEffect(() => {
    if (results?.hits.length && results.query) {
      updateStepByStepResults([
        {
          type: 'page',
          title: t('searchMenu.stepByStep'),
          results: results?.hits?.map(result => ({
            id: result.id,
            label: result.title,
            slug: appendValueToSlugsObject(`/${result.slug}`, stepByStepPageSlugs)[locale as string],
          })),
        },
      ]);
    } else {
      updateStepByStepResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  return <VirtualSearchBox indexName={indexName} query={query} />;
};

export default StepByStepResults;
