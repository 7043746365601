import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';

import { getAlgolia } from '@boss/algolia-client';
import { CHANNEL } from '@boss/constants/b2b-b2c';

import ProductResults from './products';
import StepByStepResults from './stepByStep';
import SuggestionResults from './suggestions';
import {
  getProductKeyWordSuggestionIndexName,
  getProductSearchIndexName,
  getStepByStepSearchIndexName,
} from '../../utils';

interface Props {
  query: string;
}

const SearchResults = ({ query }: Props) => {
  const { locale } = useRouter();

  const searchClient = useMemo(() => getAlgolia({}).algoliaClient, []);
  const sharedClient = useMemo(() => getAlgolia({ isShared: true }).algoliaClient, []);

  const productIndexName = useMemo(() => getProductSearchIndexName(locale as string), [locale]);
  const stepByStepIndexName = useMemo(() => getStepByStepSearchIndexName(locale as string), [locale]);
  const suggestionIndexName = useMemo(() => getProductKeyWordSuggestionIndexName(locale as string), [locale]);

  return (
    <>
      <InstantSearch indexName={suggestionIndexName} searchClient={searchClient}>
        <Configure hitsPerPage={5} />
        <SuggestionResults indexName={suggestionIndexName} query={query} />
      </InstantSearch>
      <InstantSearch indexName={productIndexName} searchClient={searchClient}>
        <Configure hitsPerPage={5} />
        <ProductResults indexName={productIndexName} query={query} />
      </InstantSearch>
      <InstantSearch indexName={stepByStepIndexName} searchClient={sharedClient}>
        <Configure filters={`channel:"${CHANNEL}"`} hitsPerPage={5} />
        <StepByStepResults indexName={stepByStepIndexName} query={query} />
      </InstantSearch>
    </>
  );
};

export default SearchResults;
