import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useHits } from 'react-instantsearch';

import { searchPageSlugs } from '@boss/constants/b2b-b2c';
import { ISearchObject } from '@boss/types/b2b-b2c';

import { useSearchMenuResults } from '../../hooks';
import VirtualSearchBox from '../Algolia/VirtualSearchBox';

interface Props {
  query: string;
  indexName: string;
}

interface SearchSuggestion extends ISearchObject {
  query: string;
}

const SuggestionResults = ({ query, indexName }: Props) => {
  const { results } = useHits<SearchSuggestion>();
  const { locale } = useRouter();

  const { updateSearchSuggestions } = useSearchMenuResults();

  useEffect(() => {
    if (results?.hits.length && results.query) {
      updateSearchSuggestions([
        {
          type: 'suggestion',
          results: results?.hits?.map(result => ({
            id: result.id,
            label: result.query,
            slug: `${searchPageSlugs[locale as string]}?q=${result.query}`,
          })),
        },
      ]);
    } else {
      updateSearchSuggestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  return <VirtualSearchBox indexName={indexName} query={query} />;
};

export default SuggestionResults;
