import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useHits } from 'react-instantsearch';

import { ISearchProduct } from '@boss/types/b2b-b2c';

import { useProductMap, useSearchMenuResults } from '../../hooks';
import VirtualSearchBox from '../Algolia/VirtualSearchBox';

interface Props {
  query: string;
  indexName: string;
}

const ProductResults = ({ query, indexName }: Props) => {
  const { results } = useHits<ISearchProduct>();
  const { t } = useTranslation();
  const { mapProductSlug } = useProductMap();

  const { updateProductResults } = useSearchMenuResults();

  useEffect(() => {
    if (results?.hits.length && results.query) {
      updateProductResults([
        {
          type: 'product',
          title: t('searchMenu.products'),
          results: results?.hits?.map(product => ({
            id: product.id,
            name: product.name,
            price: product.fromPrice?.price,
            image: {
              url: product.packshot.src ?? '',
            },
            slug: mapProductSlug({ productName: product.name, id: product.id }),
          })),
        },
      ]);
    } else {
      updateProductResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  return <VirtualSearchBox indexName={indexName} query={query} />;
};

export default ProductResults;
