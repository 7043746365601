import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { FormEvent } from 'react';

import { useSubmitForm } from '../../client-queries';
import { useForm } from '../../hooks';
import { FormValues } from '../DynamicForm';
import FormFieldMapper, { FormField } from '../Mappers/FormFieldMapper';

const SubscribeForm = () => {
  const { t } = useTranslation('forms');

  const successToast = {
    title: t('success.title'),
    description: t('success.subscribeSubtitle'),
  };
  const errorToast = {
    title: t('errors.submitTitle'),
    description: t('errors.submitSubtitle'),
  };

  const { mutate: submitForm, isLoading: isSubmitting } = useSubmitForm({
    toasts: { success: successToast, error: errorToast },
  });
  const { generateValidationSchema, getInitialValues, verifyRecaptcha } = useForm();

  const fields: FormField[] = [
    {
      label: '',
      required: false,
      inputClassName: 'pl-3.75 pr-14.5 h-12 w-full rounded-lg text-black outline-none',
      name: 'email',
      validationType: 'email',
      type: 'input',
    },
  ];

  const formik = useFormik<FormValues>({
    initialValues: getInitialValues(fields),
    validationSchema: generateValidationSchema(fields),
    onSubmit: (vals: FormValues) => submitForm({ ...vals, subscribetonewsletter: true, type: 'ColoraNewsletter' }),
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValid = await verifyRecaptcha();

    if (isValid) {
      formik.handleSubmit(e);
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <div className="flex w-full flex-col">
        <label className="h4 pb-5" htmlFor="subscribeForm">
          {t('fields.subscribeNewsletterAlt')}
        </label>
        <div className="flex">
          <FormFieldMapper className="-mr-12" field={fields[0]} formik={formik} />
          <button
            className="bg-blue rounded-38 duration-400 ease ml-auto inline-block h-12 whitespace-nowrap px-6 py-1.5 text-xl text-white transition"
            type="submit"
          >
            {isSubmitting ? t('buttons.submitting') : t('buttons.subscribe')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SubscribeForm;
