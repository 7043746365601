import { useCookies } from 'react-cookie';

import { COOKIE_EXPIRATION_TIME_1_YEAR } from '@boss/constants/b2b-b2c';
import { useIsPwa, useIsResponsive } from '@boss/hooks';
import { PWAPopup } from '@boss/ui';

const PWAWrapper = () => {
  const PWA = useIsPwa();
  const isMobile = useIsResponsive();
  const [cookies, setCookie] = useCookies(['PWA_DISMISSED']);
  const dismissed = cookies.PWA_DISMISSED === 'true';

  const handleDismiss = () => {
    setCookie('PWA_DISMISSED', true, {
      maxAge: COOKIE_EXPIRATION_TIME_1_YEAR,
    });
  };

  return <>{!dismissed && isMobile && !PWA.standalone && <PWAPopup {...PWA} onClick={handleDismiss} />}</>;
};

export default PWAWrapper;
